<template>
  <div class="SelectMain">
    <div class="GradientBorderContainer">
      <div class="PolygonNetwork">
        <div class="top"><img :src="EvMatic"></div>
        <div class="tips">
          <div class="lf"><img :src="Warning"></div>
          <div class="rf">
            <p>{{ $t('network.tips') }}</p>
          </div>
        </div>

        <!-- <button @click="manualRefresh">手动刷新</button> -->
        <div class="PolygonNetwork_b" @click="manualRefresh">
          {{ countdown }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EvMatic from '@/assets/img/EvMatic2.svg'
import Warning from '@/assets/img/Warning.svg'

export default {
  name: "SelectNetwork",
  data() {
    return {
      EvMatic: EvMatic,
      Warning: Warning,
      timer: null, // 计时器变量
      countdown: 5, // 倒计时时间（单位：秒）
    }
  },
  components: {},
  mounted() {
    // 判断是否连接钱包
    if (typeof window.ethereum !== 'undefined') {
      console.log(window.ethereum)
      console.log("已连接钱包");

      // 异步获取网络信息
      window.ethereum
        .request({method: 'eth_chainId'})
        .then((chainId) => {
          console.log(chainId)
          // 判断当前网络是否为Polygon链
          if (chainId === '0x89') {
            console.log("当前网络为Polygon链");
            this.clearTimer()
            this.$router.push({path: '/EvStake'});
          } else {
            console.log("当前网络不是Polygon链");
          }
        })
        .catch((error) => {
          console.error('获取网络信息失败:', error);
        });
    } else {
      console.log("未连接钱包");
    }
    this.startCountdown(); // 在组件挂载时开始倒计时
    this.setupBeforeUnloadListener(); // 监听 beforeunload 事件
  },
  beforeUnmount() {
    this.clearTimer(); // 在组件销毁之前清除计时器
  },
  methods: {
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--; // 倒计时减1
        } else {
          this.refreshPage(); // 倒计时结束后刷新页面
        }
      }, 1000); // 每秒更新倒计时
    },
    refreshPage() {
      this.clearTimer(); // 清除计时器
      // 执行页面刷新逻辑
      window.location.reload();
    },
    manualRefresh() {
      this.clearTimer(); // 清除计时器
      this.countdown = 5; // 重置倒计时时间
      this.startCountdown(); // 重新开始倒计时
      window.location.reload();
    },
    clearTimer() {
      clearInterval(this.timer); // 清除计时器
      this.timer = null; // 将计时器变量设置为 null
    },
    setupBeforeUnloadListener() {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    handleBeforeUnload() {
      this.clearTimer(); // 清除计时器
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes borderColorChange {

  0%,
  100% {
    background: linear-gradient(to bottom, #6EB2EE, #FA3199);
  }

  25% {
    background: linear-gradient(to bottom, #3498DB, #2ECC71);
  }

  50% {
    background: linear-gradient(to bottom, #FA3199, #6EB2EE);
  }

  75% {
    background: linear-gradient(to bottom, #F4D03F, #E74C3C);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.SelectMain {
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;

  .GradientBorderContainer {
    position: relative;
    width: 321px;
    height: 220px;
    border-radius: 28px;
    background: linear-gradient(to bottom, #6EB2EE, #FA3199);
    animation: borderColorChange 20s infinite;
    transition: background 5s ease-in-out;
    padding: 3px;
  }

  .PolygonNetwork {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      display: flex;
      justify-content: center;

      img {
        width: 79px;
        height: 43px;
        animation: bounce 2s infinite;
      }
    }

    .tips {
      display: flex;
      margin: 0 auto;
      margin-top: 20px;

      .lf {
        margin: 0 10px;

        img {
          width: 19px;
          height: 19px;
        }
      }

      .rf {
        p {
          color: #fff;
          font-size: 14px;
          line-height: 20px;
          font-family: 'Space Grotesk', sans-serif;
        }
      }
    }
  }
}

.PolygonNetwork_b {

  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  border-radius: 12px;
  border: 1px solid #81c0f1;
  margin: 0 auto;
  margin-top: 20px;
}
</style>


