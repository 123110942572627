// src/i18n.js
import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../../../sharedLang/en";
import zhTW from "../../../sharedLang/zhTW";
import ja from "../../../sharedLang/ja";
import ko from "../../../sharedLang/ko";
import pt from "../../../sharedLang/pt";
import th from "../../../sharedLang/th";
import ru from "../../../sharedLang/ru";
import de from "../../../sharedLang/de";
import vi from "../../../sharedLang/vi";
import mn from "../../../sharedLang/mn";
Vue.use(VueI18n);
const messages = {
  en: en,
  zhTW: zhTW,
  ja: ja,
  ko: ko,
  pt: pt,
  th: th,
  ru: ru,
  de: de,
  vi: vi,
  mn: mn,
};
const i18n = new VueI18n({
  locale: "en",
  messages,
});
export default i18n;
