<template>
    <div>
      <div class="nav" style="margin-bottom: 10px;">
        <ul>
          <li>
            <div class="nav_c " :class="{nav_c_type:navtype==1}" @click="onnav(1)">
              {{ $t("stake.Stake") }}
            </div>

          </li>
          <li>
            <div class="nav_c" :class="{nav_c_type:navtype==2}" @click="onnav(2)">
              {{ $t("Withdraw.Withdraw") }}
            </div>
          </li>

        </ul>
      </div>
      <div class="stakewait">
        <!-- notification start -->
        <div class="marquee">
          <Marquee :duration="15" v-if="falconAddresstype">{{ promptvalue }}</Marquee>
        </div>

        <!-- notification end -->
        <div class="coinInfomation">
          <div class="coinImg"><img :src="falocn" class="dailogo"/></div>
          <div class="coinInfo">Balance Treasure</div>
        </div>
        <Divider style="background: rgba(142, 200, 243, 0.1)"/>
        <div v-if="navtype==1">

          <div class="ownwalletInfo">
            <div class="ownTips">Falcon:</div>
            <div class="ownMoney">
              {{ formattedDaiOwnMoney }}<img class="daimi" :src="swap_falcon"/>
            </div>
          </div>
          <div class="inputInfo">
            <!-- @input="validateAmount" -->
            <input v-model.number="daiValue" type="number" class="inputStyle" @input="oninout"
                   style="width: 58.66667vw;"/>
            <button @click="(daiValue = formattedDaiOwnMoney), oninout()" class="tbnStyle ripple">
              {{ $t("network.all") }}
            </button>
          </div>

          <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("stake.Total") }}:</div>
            <div class="ownMoney">
              {{ usersinvestAmount }}<img class="swap_falcon" :src="swap_falcon"/>
            </div>
          </div>



          <div class="StakeAuthor" style="display: flex;justify-content: space-between;">

            <div class="Author"  style="width: 45%;">
              <button :disabled="empowerbutton" class="StakeBtn ripple" @click="empower"
                      v-if="typeApprove == 0">
                {{ $t("stake.Appoove") }}Falcon
              </button>
              <button class="StakeBtn" v-if="typeApprove == 1">
                {{ $t("stake.Appooveing") }}
              </button>
              <!-- appoove button end -->
            </div>



            <div class="Author" style="width: 45%;">
              <button class="StakeBtn ripple" v-if="daiValue<=0" disabled>{{ $t("stake.Stake") }}</button>
              <button class="StakeBtn ripple" disabled v-else-if="empowerbutton==false">
                {{ $t("stake.Stake") }}
              </button>
              <button class="StakeBtn " @click="deposit()" v-else-if="typedeposit == 0">
                {{ $t("stake.Stake") }}
              </button>
              <button class="StakeBtn" v-else>{{ $t("stake.Stakeing") }}</button>
            </div>
          </div>
        </div>
        <div v-if="navtype==2">
          <!-- <div class="inputInfo">
            <input v-model.number="falvalue1" type="number" class="inputStyle" style="width: 58.66667vw;"/>
            <button @click="(falvalue1 =  Math.floor(lpUsersdata.lpAmount))" class="tbnStyle ripple">
              {{ $t("network.all") }}
            </button>
          </div> -->
          <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("Withdraw.Withdrawable") }}:</div>
            <div class="ownMoney" style="display: flex;align-items: center;">
              {{ usersinvestAmount | fildecimals2 }}
              <img class="daimi" :src="swap_falcon"/>
              <!-- <span>LP</span> -->
            </div>
          </div>
          <div class="inputInfo">
            <!-- @input="validateAmount" -->
            <input v-model.number="Value2" type="number" class="inputStyle"
                   style="width: 58.66667vw;"/>
            <button @click="(Value2 = usersinvestAmount)" class="tbnStyle ripple">
              {{ $t("network.all") }}
            </button>
          </div>
          <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("Withdraw.Earnings") }}:</div>
            <div class="ownMoney" style="display: flex;align-items: center;">
              {{ getMyUserInterestdata | fildecimals2 }}
              <img class="daimi" :src="swap_falcon"/>
              <!-- <span>LP</span> -->
            </div>
          </div>
          <div class="StakeAuthor" style="display: flex;justify-content: space-between;">
            <div class="WithDrawBtn" style="width: 45%;">
            <button class="StakeBtn ripple" @click="withdraw()" v-if="Value2>0"
                    style="width: 100%;"
                   >
              {{ $t("Withdraw.Withdraw") }}
            </button>

             <button class="StakeBtn ripple" disabled v-else style="width: 100%;">
              {{ $t("Withdraw.Withdraw") }}
            </button>
          </div>

          <div class="WithDrawBtn" style="width: 45%;">
            <button class="StakeBtn ripple" v-if="finishshow==true" @click="withdrawInterestAndReward()"
                    style="width: 100%;"
            >
              {{ $t("Withdraw.Getreward") }}
            </button>

            <button class="StakeBtn ripple" disabled v-else style="width: 100%;">
              {{ $t("Withdraw.Getreward") }}
            </button>
          </div>
          </div>
          <div class="endTime" v-if="finishshow==false">
     {{ countdown }} 结束
    </div>


        </div>
        <div><img class="bStyle" :src="stylea" alt=""/></div>
        <!--loading start-->
        <div v-if="isloading" class="loading">
          <img :src="loadingimg" class="loadimg"/>
        </div>
        <!--loading end-->
        <!--loading start-->
        <div v-if="isloading2" class="loading">
          <div class="tips">正在寻找套利机会</div>
          <img :src="loadingimg" class="loadimg"/>
        </div>
        <!--loading end-->
      </div>
    </div>
  </template>
  <style scoped src="@/assets/css/EvFalocn2pro.css"></style>
  <style lang="less" scoped>
  .time_c {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
  }

  .time_c img {
    margin-left: 10px;
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;
  }

  .ownwalletInfo .ownTips {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }

  .ownwalletInfo .ownMoney {
    font-size: 20px;
    display: flex;
    align-items: center;
  }

  .ownwalletInfo .ownMoney img.daimi {
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }

  .ownwalletInfo .ownMoney img.swap_falcon {
    margin-left: 5px;
    width: 18px;
    height: 18px;
  }

  .slider {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .custom-button {
    width: 25px;
    height: 25px;
    line-height: 21px;
    background-color: rgb(172, 7, 114);
    color: #fff;
    border: 2px solid white;
    border-radius: 50px;
    text-align: center;
    font-size: 10px;
  }
  .endTime {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #08ebeb;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  </style>
  <script>
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import ProgressBar from "@/components/ProgressBar.vue";
  import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import D1 from "@/assets/img/d1.svg";
  import D2 from "@/assets/img/d2.svg";
  import D3 from "@/assets/img/d3.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import FG1 from "@/assets/img/FG1.svg";
  import falocn from "@/assets/img/FalconLogoNew.svg";
  import daimni from "@/assets/img/daimini.svg";
  import swap_falcon from "@/assets/img/swap_falcon.svg";
  import swap_matic from "@/assets/img/swap_matic.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import Falocnn from "@/plugins/Falocnn.js";
  import Falcon2js from "@/plugins/Falcon2js.js";
  import Web3 from "web3";
  import axios from "axios";
  import sign from "@/plugins/sign.js";
  import lpjs from "@/plugins/lpjs";
  import bigmember from "@/plugins/bigmember";
  import Vue from "vue";
  import {Slider} from 'vant';

  Vue.use(Slider);
  import big from "big.js";

  export default {
    data() {
      return {
        swap_usdt,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typedeposit: 0,
        dai: dai,
        FG1: FG1,
        falocn: falocn,
        daimni: daimni,
        swap_falcon: swap_falcon,
        swap_matic: swap_matic,
        loadingimg: loadingimg,
        daiValue: '',
        daiValue2: 0,
        daiValue3: 0,
        D1: D1,
        D2: D2,
        D3: D3,
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        currentProgress: 0,
        usersdata: {},
        sitedata: {},
        addresscode: "",
        empowerbutton: true,
        empowerbutton2: true,
        empowerbuttonfalcon: true,
        needinvest: "",
        Minimal: 0,
        isloading: false,
        isloading2: false,
        falconAddresstype: false,
        isOpentype: false,
        flashAddresstype: true,
        returndatadata: "",
        promptvalue: "",
        honorLeave: "",
        Falconall: false,
        Falconbalance: 0,
        getMaticBalancevalue: 0,
        falconuserdata: {
          daiAmount: 0,
          falconAmount: 0
        },
        maticvalue: 0,
        getMyUserFalconvalue: 0,
        interestvalue: 0,
        Falconv2pro: "",
        isV2: false,
        navtype: 1,
        falvalue1: 0,
        falvalue2: 0,
        falvalue3: 0,
        falvalue4: 0,
        balanceusdt: 0,
        usdtAmount: 0,
        falconAmount: 0,

        pickGetMyUserDaiInterestvalue: 0,
        endTime: null,
        countdown: '',
        lastcalltimevaluetype: false,


        calculateRewarddata: "",
        sliderdisabled: false,
        slidervalue: 0,
        getLpSharePercentagedata: "",
        getRemoveLiquidityAmountOutvalue: {
          tokenAAmount: 0,
          tokenBAmount: 0
        },
        usersinvestAmount:0,
        tokenUsersinvestAmount:0,
        inputvaluemax:0,

        Value2:0,
        getMyUserInterestdata:0,
        finishshow:false
      };
    },
    components: {
      Marquee,
      //ProgressBar,
    },
    async mounted() {

      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");

        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error(
              "Failed to obtain network information. Procedure:",
              error
            );
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        // this.daiOwnMoney = parseFloat(
        //   await web3Utils.getDaiBalance(this.addresscode)
        // );


        this.signtype();
        // this.exchangeallowance()
      }
    },
    filters: {

      fildecimals(e) {
        const multipliedNumber = e * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        //    console.log(result);
        let value = parseFloat(result);
        return value;
      },
      fildecimals2(e) {
        const multipliedNumber = e * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        //    console.log(result);

        let value = parseFloat(result);
        return value;
      },
      fildecimals3(e) {
        console.log(e)
        const multipliedNumber = e * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);

        let value = parseFloat(result);
        return value;
      },
    },
    methods: {
      funlastcalltime(value) {
        const date = value * 1000


  // 用法示例

        const storedEndTime = date
        console.log(storedEndTime, 'aaaaaaaaaaaaa')
  // localStorage.getItem('endTime');
        if (storedEndTime) {
          this.endTime = parseInt(storedEndTime, 10);
        } else {
          console.log('asdasdasdasd')
          this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
        }
        this.updateCountdown(date);
  // setInterval(this.updateCountdown, 1000);
        this.timer = setInterval(() => {
          //需要定时执行的代码
          this.updateCountdown(date)
        }, 1000)
      },
      startCountdown(duration) {
        this.endTime = Date.now() + duration * 1000;
        localStorage.setItem('endTime', this.endTime);
      },
      updateCountdown(item) {
        const now = Date.now();
        var remainingTime



        if (this.timershow == false) {
          // console.log(now)
          // console.log(item)
          if (now > item) {
            this.timershow = true
          }
          remainingTime = Math.max((item - now) / 1000, 0);
        } else {
          remainingTime = Math.max((this.endTime - now) / 1000, 0);
          if (this.endTime - now <= 0) {
            console.log('结束')
            this.finishshow = true
            clearInterval(this.timer)
          }
        }


        const days = Math.floor(remainingTime / (24 * 60 * 60));
        const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
        const seconds = Math.floor(remainingTime % 60);
        if (this.pad(hours) == null) {
          this.countdown = 0
          return
        }

        this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
      },
      pad(number) {
        return number.toString().padStart(2, '0');
      },
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign.getauthorization(this.addresscode).then((res) => {

          if (res.code == 0) {
            this.users();
            this.getMyUserInterest()
            this.balance()
            this.balance2()
          }
          this.isloading = false;
        });
      },
       // User information
    async users() {
      this.isloading = true;
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.bigmember_token.address;
      await bigmember.tokenUsers(
        this.sitedata.falcon_swap.address,
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        this.isloading = false;
        console.log(res, 'users');
       this.usersinvestAmount=res.data.investAmount
       this.endExtractTime=res.data.endExtractTime
       this.funlastcalltime(this.endExtractTime)
       this.getMyUserInterest()
        if(this.endExtractTime==0){
          this.finishshow =true;
        }
      });
    },

      async getMyUserInterest() {
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.bigmember_token.address;
      await bigmember.getMyFalconUserInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {

        console.log(res, 'getMyUserInterest');
        this.getMyUserInterestdata=res.data
      });
    },



      onnav(e) {
        this.daiValue = 0
        this.daiValue2 = 0
        this.daiValue3 = 0
        this.falvalue1 = 0
        this.falvalue2 = 0
        this.falvalue3 = 0
        this.falvalue4 = 0
        if (e == 1) {
          this.navtype = 1
        } else if (e == 2) {
          this.navtype = 2
          this.getMyUserInterest()
        }


      },



      oninout() {
        // if(this.daiValue>this.inputvaluemax){
        //   this.daiValue=this.inputvaluemax
        // }
        if (this.daiValue > 0) {

        } else {
          this.daiValue2 = 0
        }
        this.exchangeallowance()
      },



      async exchangeallowance() {
        console.log('usdt数量')
        let _decimals = 6;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address;
        spender = this.sitedata.bigmember_token.address;
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result, 'usdt数量')
          this.isloading = false;

          if (result.data * 1 >= this.daiValue) {
            this.empowerbutton = true;
          } else {
            this.empowerbutton = false;
          }
          // if (this.daiValue <= result.data * 1) {
          //   this.depositDisabled = false;
          // } else {
          //   this.depositDisabled = true;
          // }
        });
      },

      empower() {
        this.isloading = true;  // open loading
        this.Approve();
      },

      // contract Approve
      async Approve() {
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.falcon_swap.address;
        spender = this.sitedata.bigmember_token.address;
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens2(
            Address, //
            this.daiValue, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful")
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbutton = true;

              // this.userIsActive()
            } else {

              this.$Notice.warning({
                title: this.$t("stake.AuthorFailed")
                //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
              });
              this.isloading = false;
            }
          });
        } catch (error) {

          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },

      async deposit() {
        this.isloading = true;
        let _decimals = 18
        let Address;
        Address =  this.sitedata.bigmember_token.address;
        this.typedeposit = 1;
        try {
          await bigmember
            .depositFalcon(
              Address, //Contract address
              this.daiValue, //quantity
              this.addresscode, //Current address
              _decimals, //Fractional part

            )
            .then((res) => {
              console.log(res);
              this.typedeposit = 0;
              this.isloading = false;
              if (res.code == 0) {
            //    this.balance()
                this.balance2()


                this.users();

                this.getMyUserInterest()
                this.typedeposit = 0;
                this.$Notice.success({
                  title: this.$t("stake.StakeSuccessful")
                  //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                });


              } else {

                this.isloading = false;
                if (res.code < 1) {
                  this.$Notice.warning({
                    title: res.error.message
                  });
                } else {
                  this.$Notice.warning({
                    title: this.$t("stake.StakeFailed")
                    // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                  });
                }
              }
            });
        } catch (error) {

          this.$Notice.warning({
            title: this.$t("stake.StakeFailed")
            //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
          });
          // console.log(error);
        }
      },

       // withdraw
       async withdraw() {
         this.isloading = true;
        let _decimals = 18
        let Address;
        Address = this.sitedata.bigmember_token.address;
        this.typeextract = 1;
        try {
          await bigmember.withdrawFalcon(
            Address, //Contract address
            this.Value2, //quantity
            this.addresscode,//Current address
            _decimals,//Fractional part

          ).then((res) => {
            console.log(res);
            this.typeextract = 0;
            if (res.code == 0) {
              this.typeextract = 0;

              this.$Notice.success({
                title: this.$t("Withdraw.successful")
              });
              this.isloading = false;
              this.users()
              this.getMyUserInterest()
              this.typedeposit = 0;

            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("Withdraw.unsuccessfule")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }

          });
        } catch (error) {
          this.isloading = false;
          // this.typeApprove = 1;
          this.$Notice.warning({
            title: this.$t("Withdraw.unsuccessfule")
          });
          //console.log(error);
        }
      },
      async withdrawInterestAndReward() {
        this.isloading = true;
        let _decimals = 6;
        let Address;
        Address =  this.sitedata.bigmember_token.address;
        this.typeReceive = 1;
        try {
          await bigmember.withdrawFalconInterestAndReward(
            Address, //Contract address
            this.Value2, //number
            this.addresscode,//current address
            _decimals,//float
          ).then((res) => {
            //console.log(res);
            this.typeReceive = 0;
            if (res.code == 0) {
              this.typeReceive = 0;
              this.isloading = false;
              this.$Notice.success({
                title: this.$t("Withdraw.successful")
              });
              this.users()
              this.getMyUserInterest()
            } else {
              // this.typeApprove = 1;
              this.isloading = false;

              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("Withdraw.unsuccessfule")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }

          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("Withdraw.unsuccessfule")
          });
          //console.log(error);
        }
      },






      validateAmount() {

        if (this.daiValue * 1 < 1000) {
          // this.isButtonDisabled = true;
          // this.depositDisabled = true;
        } else {
          // this.isButtonDisabled = false;
          // this.depositDisabled = false;
          this.daiValue = 1000
          this.exchangeallowance();
        }
      },
      // Falcon余额
      async balance() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address;
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,
          _decimals
        ).then((result) => {
          console.log(result, "Falcon余额");
          this.Falconbalance = result.data

        });
      },
      // usdt余额
      async balance2() {
        var that = this
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.falcon_swap.address
        await Plugens.balanceOf(
          Address,
          0,
          this.addresscode,

          _decimals
        ).then((result) => {
          console.log(result, "usdt余额");
          this.balanceusdt = result.data
          this.isloading = false;

        });
      },





      isTimestampLessThanCurrent(timestamp) {
        const currentTimestamp = Date.now();
        return timestamp < currentTimestamp;
      },










      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.balanceusdt) return "0.0000";

        const multipliedNumber = this.balanceusdt * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;

        let value = parseFloat(result);


        return value;
      },
      formattedFalconbalance() {
        if (!this.Falconbalance) return "0.0000";
        console.log(this.Falconbalance)
        const multipliedNumber = this.Falconbalance * 10000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 10000;
        let value = parseFloat(result);
        console.log(value)

        return value
      },
      formattedDaiOwnMoney2() {
        if (!this.stakedMoney) return "0.0000";
        const multipliedNumber = this.stakedMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value;
      },
    },
  };
  </script>
