<template>
  <div class="stakewait">

    <div>

      <div class="list_c" v-for="(item,index) in list" :key="index">
        <div class="list_c_l">
          <img src="../assets/img/swap_dai.svg" alt="">
        </div>
        <div class="list_c_r">
          <div class="list_c_r_title">
            <p class="p1">aave V3</p>
            <p class="p2">#A00003</p>
          </div>
          <div class="list_c_r_list">
            <p>我的本金: <span>50,0OO DAI</span></p>
          </div>
          <div class="currP">
            <ProgressBar2 :progress="currentProgress"></ProgressBar2>
          </div>
          <div class="list_c_r_b">
            <button :disabled="empowerbutton" class="StakeBtn ripple">
              立即购买
            </button>
            <!-- <button class="StakeBtn" v-if="typeApprove == 1">
              {{ $t("stake.Appooveing") }}
            </button> -->
            <p class="p1">7D 20:08:01</p>
          </div>
        </div>
      </div>
    </div>

    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg"/>
    </div>

    <!--loading end-->
  </div>
</template>


<script>
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_matic from "@/assets/img/swap_matic.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import ProgressBar2 from "@/components/ProgressBar2.vue";
// import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import daimni from "@/assets/img/daimini.svg";

import contract from "@/plugins/contract.js";
import price from "@/plugins/price.js";
import sign from "@/plugins/sign.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import axios from "axios";

export default {
  data() {
    return {
      swap_usdc,
      swap_usdt,
      swap_falcon,
      swap_matic,
      swap_dai,
      swap_arrow,
      swap_close,
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      daimni: daimni,
      loadingimg: loadingimg,
      daiOwnMoney: 0,
      currentProgress: 20,
      sitedata: {},
      addresscode: "",
      isloading: false,
      empowerbutton: false,
      list: [
        {id: 1},
        {id: 1},
        {id: 1},
        {id: 1},
      ]

    };
  },
  components: {
    // Marquee,
    ProgressBar2,
  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({method: "eth_chainId"})
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({path: "/"});
            return;
          }
        })
        .catch((error) => {
          console.error("Failed to obtain network information. Procedure:", error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(this.addresscode)
      // );
      this.signtype()


      //  console.log(this.daiOwnMoney.toFixed(6));


      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {

          if (res.code == 0) {
            //   this.users();
            //   // this.exchangeallowance();
            //   this.limitAllInvestAmount();
            //   this.minInvestAmount();
          }
          this.isloading = false;
        });
    },


  },
  computed: {},
};
</script>
<style scoped src="@/assets/css/Tobelisted.css"></style>
<style lang="less" scoped>
.ripple {
  position: relative;
  overflow: hidden;
}

.list_c {
  background-color: rgba(137, 136, 136, 0.3);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  margin-bottom: 10px;
}

.list_c_l {
  width: 35px;
  flex-shrink: 0;
}

.list_c_l img {
  width: 35px;
  height: 35px;
}

.list_c_r {
  width: 100%;
  padding-left: 15px;
}

.list_c_r_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_c_r_title .p1 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.list_c_r_title .p2 {
  color: #999;
  font-size: 12px;
}

.list_c_r_list p {
  font-size: 12px;
  color: #c0c0c0;

  span {
    font-weight: 600;
    color: #fff;
  }
}

.currP {
  margin-top: 8px;
}

.list_c_r_b {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  .p1 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }
}


.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: solid 1px rgba(70, 70, 70, 0.581);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px);
  -o-backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  color: #81c0f1;
  z-index: 10000 !important;
  top: 0;
  left: 0;
  animation: fadeIn 0.3s ease-out forwards;

  .loadimg {
    width: 118px;
    height: 118px;
  }
}
</style>
