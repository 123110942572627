import { render, staticRenderFns } from "./record.vue?vue&type=template&id=5235147e&scoped=true"
import script from "./record.vue?vue&type=script&lang=js"
export * from "./record.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/Tobelisted.css?vue&type=style&index=0&id=5235147e&prod&scoped=true&lang=css&external"
import style1 from "./record.vue?vue&type=style&index=1&id=5235147e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5235147e",
  null
  
)

export default component.exports