import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
import i18n from "../utils/i18n";
// tb合约pg合约方法
export default {
  async tokenUsers(currentAddress, ContractAddress, _decimals,aave_token,token) {
    try {

      let Factory;
      Factory = require("../json/abi/property.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
    //   const amountApproved = big(value)
    //   .times(10 ** 18)
    //   .toFixed();

      const balance = await contract.tokenUsers(aave_token,token,currentAddress)
      console.log(aave_token,token,currentAddress,'111111111111111111111111')
      // ethers转json
      let data = balance
      let newData = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (typeof data[key] === 'object' && data[key]._isBigNumber) {
            newData[key] = data[key].toString();
          } else {
            newData[key] = data[key];
          }
        }
      }
      newData.in_amount = big(newData.in_amount)
      .div(10 ** 18)
      .toFixed();
      newData.out_amount = big(newData.out_amount)
      .div(10 ** 18)
      .toFixed();
      newData.team_in_amount = big(newData.team_in_amount)
      .div(10 ** 18)
      .toFixed();
      newData.team_out_amount = big(newData.team_out_amount)
      .div(10 ** 18)
      .toFixed();

      return {code: "0", data: newData};
    } catch (error) {
      console.log(error);
    }
  },



};
