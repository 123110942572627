import Web3 from "web3";
import big from "big.js";
import {ethers} from "ethers";
// tb合约pg合约方法
export default {
//
  async regionalCommunity(currentAddress, ContractAddress, _decimals,token) {
    try {
      //   let position = "";
      // console.log(_decimals)
      let Factory;
      Factory = require("../json/abi/select.json");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(ContractAddress, Factory.abi, provider); // 使用 ABI 和合约地址创建合约实例
      const balance = await contract.regionalCommunity(token,currentAddress)

 // ethers转json
 let data = balance
 let newData = {};
 for (const key in data) {
   if (data.hasOwnProperty(key)) {
     if (typeof data[key] === 'object' && data[key]._isBigNumber) {
       newData[key] = data[key].toString();
     } else {
       newData[key] = data[key];
     }
   }
 }
 newData.minPerformance = big(newData.minPerformance)
 .div(10 ** 18)
 .toFixed();
 newData.largestChildPerformance = big(newData.largestChildPerformance)
 .div(10 ** 18)
 .toFixed();
      return {code: "0", data: newData};
    } catch (error) {
       console.log(error);
    }
  },


};
