<template>
    <div>
        <div class="nav" style="margin-bottom: 10px;">
      <ul>
        <li>
          <div class="nav_c " :class="{nav_c_type:navtype==1}" @click="onnav(1)">
            我的
          </div>

        </li>
        <li>
          <div class="nav_c" :class="{nav_c_type:navtype==2}" @click="onnav(2)">
            团队
          </div>
        </li>

      </ul>
    </div>
    <div class="stakewait">
        <div v-if="navtype==1">
      <div class="coinInfomation">
        <!-- <div class="coinImg"><img :src="falocn" class="dailogo"/></div> -->
        <div class="coinInfo">个人资产分析</div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div class="ownwalletInfo">
        <div class="ownTips">DAI存入本金:</div>
        <div class="ownMoney">
          {{tokenUsersDAI.in_amount | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">DAI收回本金:</div>
        <div class="ownMoney">
          {{tokenUsersDAI.out_amount | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
     <div class="ownwalletInfo">
        <div class="ownTips">资产状态:</div>
        <div class="ownMoney" v-if="(tokenUsersDAI.out_amount-tokenUsersDAI.in_amount)>=0">
            已回本
        </div>
        <div class="ownMoney" v-else>
            未回本
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips" v-if="(tokenUsersDAI.out_amount-tokenUsersDAI.in_amount)>=0">已收回成本:</div>
        <div class="ownTips" v-else>未收回成本:</div>
        <div class="ownMoney">
          {{ Math.abs(tokenUsersDAI.out_amount-tokenUsersDAI.in_amount) | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>

      <div style="height: 3px;background-color: #8FC1E9;margin-top: 20px;margin-bottom: 20px;"></div>


      <div class="ownwalletInfo">
        <div class="ownTips">USDT存入本金:</div>
        <div class="ownMoney">
            {{tokenUsersUSDT.in_amount | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">USDT收回本金:</div>
        <div class="ownMoney">
            {{tokenUsersUSDT.out_amount | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div class="ownwalletInfo">
        <div class="ownTips">资产状态:</div>
        <div class="ownMoney" v-if="(tokenUsersUSDT.out_amount-tokenUsersUSDT.in_amount)>=0">
             已回本
        </div>
        <div class="ownMoney" v-else>
            未回本
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips"  v-if="(tokenUsersUSDT.out_amount-tokenUsersUSDT.in_amount)>=0">已收回成本:</div>
        <div class="ownTips" v-else>未收回成本:</div>
        <div class="ownMoney">
            {{Math.abs(tokenUsersUSDT.out_amount-tokenUsersUSDT.in_amount) | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
    </div>
    <div v-if="navtype==2">
      <div class="coinInfomation">
        <!-- <div class="coinImg"><img :src="falocn" class="dailogo"/></div> -->
        <div class="coinInfo">网体资产分析</div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div class="ownwalletInfo">
        <div class="ownTips">DAI存入本金:</div>
        <div class="ownMoney">
          {{tokenUsersDAI.team_in_amount | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">DAI收回本金:</div>
        <div class="ownMoney">
          {{tokenUsersDAI.team_out_amount | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
     <div class="ownwalletInfo">
        <div class="ownTips">资产状态:</div>
        <div class="ownMoney" v-if="(tokenUsersDAI.team_out_amount-tokenUsersDAI.team_in_amount)>=0">
            已回本
        </div>
        <div class="ownMoney" v-else>
             未回本
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips" v-if="(tokenUsersDAI.team_out_amount-tokenUsersDAI.team_in_amount)>=0">已收回成本:</div>
        <div class="ownTips" v-else>未收回成本:</div>
        <div class="ownMoney">
          {{Math.abs(tokenUsersDAI.team_out_amount-tokenUsersDAI.team_in_amount) | fildecimals}}<img class="daimi" :src="swap_dai"/>
        </div>
      </div>

      <div style="height: 3px;background-color: #8FC1E9;margin-top: 20px;margin-bottom: 20px;"></div>

      
      <div class="ownwalletInfo">
        <div class="ownTips">USDT存入本金:</div>
        <div class="ownMoney">
            {{tokenUsersUSDT.team_in_amount | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips">USDT收回本金:</div>
        <div class="ownMoney">
            {{tokenUsersUSDT.team_out_amount | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
      <Divider style="background: rgba(142, 200, 243, 0.1)"/>
      <div class="ownwalletInfo">
        <div class="ownTips">资产状态:</div>
        <div class="ownMoney" v-if="(tokenUsersUSDT.team_out_amount-tokenUsersUSDT.team_in_amount)>=0">
            已回本
        </div>
        <div class="ownMoney" v-else>
            未回本
        </div>
      </div>
      <div class="ownwalletInfo">
        <div class="ownTips" v-if="(tokenUsersUSDT.team_out_amount-tokenUsersUSDT.team_in_amount)>=0">已收回成本:</div>
        <div class="ownTips" v-else>未收回成本:</div>
        <div class="ownMoney">
            {{Math.abs(tokenUsersUSDT.team_out_amount-tokenUsersUSDT.team_in_amount) | fildecimals}}<img class="daimi" :src="swap_usdt"/>
        </div>
      </div>
    </div>
  
      <div><img class="bStyle" :src="stylea" alt=""/></div>
      <!--loading start-->
      <div v-if="isloading" class="loading">
        <img :src="loadingimg" class="loadimg"/>
      </div>
      <!--loading end-->
    </div>
</div>
  </template>
  <style scoped src="@/assets/css/EvFalocnV4.css"></style>
  <style lang="less" scoped>
  .time_c {
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
  }
  
  .nav {
    width: 100%;
    border-radius: 6px;
    margin: 0 auto;
  }
  
  .nav ul {
    width: 330px;
    margin: 0 auto;
    list-style: none;
    display: flex;
    justify-content: space-around;
  }
  
  .nav ul li {
    flex: 1;
  }
  
  .nav ul li .nav_c {
    color: #808383;
    font-family: "Poppins", sans-serif;
    padding: 8px;
    border-radius: 0px;
    background: #120245;
    border: 2px solid #05080b;
    display: block;
    text-align: center;
  }
  
  .nav ul li .nav_c_type {
    color: aqua;
    font-family: "Poppins", sans-serif;
    padding: 8px;
    font-weight: 800;
    border-radius: 5px;
    background: linear-gradient(to bottom, #5e0463, rgba(44, 144, 232, 0.603));
    color: white;
    border: 2px solid #05080b;
    display: block;
  }
  
  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;
  }
  
  .ownwalletInfo .ownTips {
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }
  
  .ownwalletInfo .ownMoney {
    font-size: 20px;
  }
  
  .ownwalletInfo .ownMoney img.daimi {
    width: 18px;
    height: 18px;
    margin-left: 5px;
  }
  
  .ownwalletInfo .ownMoney img.swap_falcon {
    margin-left: 5px;
    width: 18px;
    height: 18px;
  }
  .stakewait .ownwalletInfo .ownMoney img.daimi {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .ownMoney{
    display: flex;
    align-items: center;
  }
  </style>
  <script>
  import ProgressBar from "@/components/ProgressBar.vue";
  import Marquee from "@/components/Marquee.vue";
  import web3Utils from "@/utils/web3Utils.js";
  import dai from "@/assets/img/DaiStablecoin.svg";
  import loadingimg from "@/assets/img/loading.svg";
  import FG1 from "@/assets/img/FG1.svg";
  import falocn from "@/assets/img/FalconLogoNew.svg";
  import daimni from "@/assets/img/daimini.svg";
  import stylea from "@/assets/img/stylea.png";
  import contract from "@/plugins/contract.js";
  import siteapi from "@/json/siteapi.json";
  import Plugens from "@/plugins/index";
  import Falocnn from "@/plugins/Falocnn.js";
  import Web3 from "web3";
  import axios from "axios";
  import property from "@/plugins/property";
  import sign from "@/plugins/sign.js";
  import swap_usdt from "@/assets/img/swap_usdt.svg";
  import swap_dai from "@/assets/img/swap_dai.svg";
  
  export default {
    data() {
      return {
        swap_usdt,
        swap_dai,
        isButtonDisabled: true,
        depositDisabled: true,
        typeApprove: 0,
        typedeposit: 0,
        dai: dai,
        FG1: FG1,
        falocn: falocn,
        daimni: daimni,
        loadingimg: loadingimg,
        daiValue: "100",
        daiOwnMoney: 0,
        stylea: stylea,
        stakedMoney: 0,
        currentProgress: 0,
        usersdata: {},
        sitedata: {},
        addresscode: "",
      
        needinvest: "",
        Minimal: 0,
        isloading: false,
        falconAddresstype: true,
        
        flashAddresstype: true,
        returndatadata: "",
        promptvalue: "",
        honorLeave: "",
        navtype: 1,
        tokenUsersDAI:{},
        tokenUsersUSDT:{}
      };
    },
    components: {
      Marquee,
      //ProgressBar,
    },
    filters: {

fildecimals(e) {
  const multipliedNumber = e * 10000;
  const truncatedNumber = Math.trunc(multipliedNumber);
  const result = truncatedNumber / 10000;
  //    console.log(result);
  //    console.log(this.daiOwnMoney);
  let value = parseFloat(result);
  return value;
},
},
    async mounted() {
  
      // Determine whether the wallet is connected
      if (typeof window.ethereum !== "undefined") {
        //console.log("Connected wallet");
  
        // Async obtaining network information
        window.ethereum
          .request({method: "eth_chainId"})
          .then((chainId) => {
            // is Polygon?
            if (chainId === "0x89") {
              // console.log("The current network is a Polygon chain");
              // this.$router.push({path:'/EvStake'});
            } else {
              //  console.log("The current network is not Polygon chain");
              this.$router.push({path: "/"});
              return;
            }
          })
          .catch((error) => {
            console.error(
              "Failed to obtain network information. Procedure:",
              error
            );
          });
      } else {
        //console.log("Unconnected wallet");
      }
      this.sitedata = siteapi;
      const currentAddress = await web3Utils.connectWallet();
      // console.log(currentAddress);
      this.addresscode = currentAddress;
      if (currentAddress) {
        this.daiOwnMoney = parseFloat(
          await web3Utils.getDaiBalance(this.addresscode)
        );
  
        //  console.log(this.daiOwnMoney.toFixed(6));
  
        this.signtype();
      
      }
    },
    methods: {
      // 签名验证
      async signtype() {
        this.isloading = true;
        await sign.getauthorization(this.addresscode).then((res) => {
  
          if (res.code == 0) {
            // this.users();
            
            this.tokenUsers()
           
         
            
        
          }
          this.isloading = false;
        });
      },
     
      onnav(e) {
     
      if (e == 1) {
        this.navtype = 1
      } else if (e == 2) {
        this.navtype = 2
        
      }


    },
    
   
 
      // User information
      async users() {
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .users(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            // console.log(res.data,'levellevellevel')
            this.honorLeave = res.data.level
  
            //console.log(res, "User information");
            this.usersdata = res.data;
            this.stakedMoney = res.data.investAmount * 1;
            //console.log(this.stakedMoney);
            //const total = 14000;
            //this.currentProgress = (this.stakedMoney / total) * 100;
          });
      },
      async tokenUsers() {
        this.isloading = true;
        //console.log(this.sitedata);
        let _decimals = 18;
        let ContractAddress;
        //console.log("User information");
        ContractAddress = this.sitedata.property_token.address;
        await property
          .tokenUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.sitedata.aave_token.address,
            this.sitedata.DAI_swap.address
          )
          .then((res) => {
            console.log(res.data,'tokenUsers DAI')
            this.tokenUsersDAI=res.data
          });
          await property
          .tokenUsers(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,
            this.sitedata.aave_token.address,
            this.sitedata.USDT_swap.address
          )
          .then((res) => {
            console.log(res.data,'tokenUsers USDT')
            this.tokenUsersUSDT=res.data
            // this.isloading = false;
          });
      },
      
      
  
  
      warning(nodesc) {
        this.$Notice.warning({
          title: nodesc ? "" : this.$t("network.stake"),
          //desc: nodesc ? "" : this.$t("network.stake"),
        });
      },
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.daiOwnMoney) return "0.0000";
  
        const multipliedNumber = this.daiOwnMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);
        //    console.log(this.daiOwnMoney);
        let value = parseFloat(result);
  
  
        return value;
      },
      formattedDaiOwnMoney2() {
        if (!this.stakedMoney) return "0.0000";
        const multipliedNumber = this.stakedMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value;
      },
    },
  };
  </script>
  