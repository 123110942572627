<template>
  <div class="stakewait">
    <!-- notification start -->
    <div class="marquee">
      <!-- <Marquee :duration="15">{{ $t("network.falconv4") }}</Marquee> -->
      <!-- <Marquee :duration="15">{{ $t("menu.LogiV3tips") }}</Marquee> -->
    </div>
    ido4
    <!-- notification end -->
    <div class="coinInfomation">
      <div class="coinImg"><img :src="falocn" class="dailogo"/></div>
      <div class="coinInfo">{{ $t("menu.Ido") }}</div>
    </div>
    <Divider style="background: rgba(142, 200, 243, 0.1)"/>
    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("ido.mynumber") }}:</div>
    </div>
    <div class="inputInfo">
      <input class="inputStyle" v-model="decimalsvalue1"/>
    </div>


    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("ido.myrelease") }}:</div>
    </div>
    <div class="inputInfo">
      <input class="inputStyle" v-model="decimalsvalue2"/>
    </div>


    <div class="ownwalletInfo">
      <div class="ownTips">{{ $t("ido.waitrelease") }}: ({{ steps }}/12)</div>
    </div>
    <div class="inputInfo">
      <input class="inputStyle" v-model="decimalsvalue3"/>
    </div>


    <button @click="release()" v-if="getpledgepervalue>0" class="tbnStyle">{{ $t("ido.get") }}</button>
    <button v-else class="tbnStyle tbnStyle_type">{{ $t("ido.get") }}</button>


    <div><img class="bStyle" :src="stylea" alt=""/></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg"/>
    </div>
    <!--loading end-->
  </div>
</template>

<style lang="less" scoped>
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(133deg,
  rgba(7, 110, 150, 0.3),
  rgba(132, 2, 72, 0.6));
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;
  position: relative;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: solid 1px rgba(70, 70, 70, 0.581);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    color: #81c0f1;
    z-index: 10000 !important;
    top: 0;
    left: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .loadimg {
      width: 118px;
      height: 118px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .bStyle {
    width: 290px;
    height: 76px;
  }

  .currP {
    margin-bottom: 20px;
  }

  .autoProcess {
    display: flex;
    justify-content: flex-start;

    .a1 {
      margin-right: 8px;
      height: 15px;
    }

    .a1 input {
      width: 15px;
      height: 15px;
    }

    .a2 {
      height: 15px;
      position: relative;
      width: 100%;

      span {
        position: absolute;
        top: -2px;
      }
    }

    // input {
    //     width: 15px;
    //     height: 15px;
    //     border-radius: 5px;
    // }

    // span {
    //     position: absolute;
    //     top: 0px;
    //     left: 25px;
    // }
  }

  .tbnStyle {

    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: rgb(2, 0, 0);
    font-size: 20px;
    background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
  }

  .tbnStyle_type {
    background: #ddd;
  }


  .nowPrice {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .StakeAuthor {


    .selectstyle #dropdown {

      border: 3px solid #81c0f1;
      height: 53px;
      box-sizing: border-box;
      border-radius: 8px;
      margin: 10px 0;
      width: 100%;
      background-color: transparent;
      color: white;
      font-size: 15px;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;


      background-size: 20px;
      padding: 10px;

      background: url("../assets/img/dw.svg") no-repeat right 10px center;
      font-size: 14px;

    }


  }

  .hr1 {
    border: none;
    border-top: 1px solid rgba(142, 200, 243, 0.3);
    /* 蓝色线条 */
  }

  .tvl {
    font-size: 10px;
    margin: 5px 0 5px 0;

    span {
      font-weight: 800;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .StakeInfo {
    display: flex;
    margin-bottom: 10px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 6px;
    }

    .StakeMoney {
      text-align: right;
      font-size: 20px;
      font-weight: 600;

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      flex: 1;
      text-align: center;
      height: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      flex: 1;
      color: rgb(72, 2, 64);
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .marquee {
    margin-bottom: 15px;
    color: #81c0f1;
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      left: 10px;
      font-weight: bold;
    }
  }
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FG1 from "@/assets/img/FG1.svg";
import falocn from "@/assets/img/falocn.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import Falocnn from "@/plugins/Falocnn.js";
import idojs2 from "@/plugins/idojs2.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
  data() {
    return {
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      FG1: FG1,
      falocn: falocn,
      daimni: daimni,
      loadingimg: loadingimg,
      daiValue: "100",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      falconAddresstype: true,
      isOpentype: false,
      flashAddresstype: true,
      returndatadata: "",
      promptvalue: "",
      honorLeave: "",
      selected: 0,
      options: [
        {text: this.$t("ido.select"), value: "none"},
        {text: this.$t("ido.flashmonkey"), value: "0x18FBD380aD51A4bcb3A1A8a89107483d10B716c0"},
        {text: this.$t("ido.flashdragon"), value: "0xd16BCe7f276947Cf61453E74a67b267F0e807DD6"},
        {text: this.$t("ido.powermonger"), value: "0x357AA453A873eF716eA69088C85Be4aDBDA6282e"},
        {text: this.$t("ido.fashfalcon2"), value: "0x14c26324f39b35F908B79c2c240c187520f6D772"},
        {text: this.$t("ido.fashFalcon"), value: "0x7cDFf0d91181993eBD88e058095060e71eAB3Eba"},
      ],
      Tobereleased: 0,
      totalAmount: 0,
      releasedAmount: 0,
      releaseCount: 0,
      getpledgepervalue: 0,
      haveAmount: 0,
      steps: 0


    };
  },
  components: {

    //ProgressBar,
  },

  async mounted() {

    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({method: "eth_chainId"})
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({path: "/"});
            return;
          }
        })
        .catch((error) => {
          console.error(
            "Failed to obtain network information. Procedure:",
            error
          );
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );

      //  console.log(this.daiOwnMoney.toFixed(6));

      this.signtype();
      // this.exchangeallowance()
    }
  },
  methods: {
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign.getauthorization(this.addresscode).then((res) => {

        if (res.code == 0) {
          // this.users();
          this.vestingSchedules()

          // this._flashAddress()
        }
        this.isloading = false;
      });
    },


    async vestingSchedules() {

      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.ido_token2.address;
      await idojs2.vestingSchedules(
        this.addresscode,
        ContractAddress,
        _decimals,
      ).then((res) => {
        console.log(res, "vestingSchedules");
        this.totalAmount = res.data.totalAmount
        this.releasedAmount = res.data.releasedAmount * 1
        this.releaseCount = res.data.releaseCount
        this.Tobereleased = res.data.totalAmount - res.data.releasedAmount

        this.haveAmount = res.data.haveAmount

        this.getpledgeper()

      });
    },
    async getpledgeper() {

      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.ido_token2.address;
      await idojs2.getpledgeper(
        this.addresscode,
        ContractAddress,
        _decimals,
      ).then((res) => {
        console.log(res, "getpledgeper");
        this.getpledgepervalue = res.data * 1;//+this.releasedAmount;
        let remainingAmount = this.totalAmount - this.releasedAmount;

        let stepAmount = this.totalAmount / 12;
        if (stepAmount == 0) {
          this.steps = 0
        } else {
          this.steps = Math.ceil(remainingAmount / stepAmount);
        }

        console.log(this.steps)

      });
    },
    // User information
    async users() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          // console.log(res.data,'levellevellevel')
          this.honorLeave = res.data.level

          //console.log(res, "User information");
          this.usersdata = res.data;
          this.stakedMoney = res.data.investAmount * 1;
          //console.log(this.stakedMoney);
          //const total = 14000;
          //this.currentProgress = (this.stakedMoney / total) * 100;
        });
    },
    // 提取
    async release() {
      this.isloading = true
      let _decimals = 18;
      let ContractAddress;
      ContractAddress = this.sitedata.ido_token2.address;
      try {
        await idojs2.release(
          this.addresscode,
          ContractAddress,
          _decimals
        ).then((res) => {
          console.log(res, "release");
          if (res.code == 0) {
            this.isloading = false;
            this.vestingSchedules()

            this.$Notice.success({
              title: this.$t("network.flashloansOK"),
              //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
            });
          } else {
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("network.flashloansNo")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }

          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("network.flashloansNo"),
          //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
        });
        //console.log(error);
      }
    },


    warning(nodesc) {
      this.$Notice.warning({
        title: nodesc ? "" : this.$t("network.stake"),
        //desc: nodesc ? "" : this.$t("network.stake"),
      });
    },
  },
  computed: {
    decimalsvalue1() {
      if (!this.totalAmount) return "0.00";

      const multipliedNumber = this.totalAmount * 1000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 1000;
      //    console.log(result);
      //    console.log(this.daiOwnMoney);
      let value = parseFloat(result);


      return value;
    },
    decimalsvalue2() {
      if (!this.getpledgepervalue) return "0.00";
      const multipliedNumber = this.getpledgepervalue * 1000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 1000;
      let value = parseFloat(result);
      //console.log(value)
      return value;
    },
    decimalsvalue3() {
      if (!this.haveAmount) return "0.00";
      const multipliedNumber = this.haveAmount * 1000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 1000;
      let value = parseFloat(result);
      //console.log(value)
      return value;
    },
  },
};
</script>
