<template>
  <div>
    <h1>EvWatchOnlyWalletAdd</h1>
    <h2>THIS IS Ev EvWatchOnlyWalletAdd</h2>
    <ul class="example">
      <li>
        <router-link to="/EvContract">Ev Withdraw</router-link>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "EvWatchOnlyWalletAdd",
};
</script>

<style lang="less" scoped>
h1 {
  color: red;
}
</style>
