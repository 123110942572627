import Vue from "vue";
import App from "./App.vue";
import i18n from "./utils/i18n";
import "normalize.css";
import "@/assets/css/index.css";
import router from "./router";
import store from "./store";
import 'vant/lib/index.css';
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

Vue.use(ViewUI);

Vue.config.productionTip = false;
document.documentElement.style.setProperty('--flash-dai-content', `"${process.env.VUE_APP_TITLE_NAME}"`);
new Vue({
  render: (h) => h(App),
  router,
  i18n,
  store,
}).$mount("#app");
