<template>
  <div>
    <h1>EvWatchOnlyWallet</h1>
    <h2>THIS IS Ev EvWatchOnlyWallet</h2>
    <ul class="example">
      <li>
        <router-link to="/EvContract">Ev Withdraw</router-link>
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: "EvWatchOnlyWallet",
};
</script>

<style lang="less" scoped>
h1 {
  color: rgb(6, 58, 147);
}
</style>
